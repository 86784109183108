<template>
  <div class="common-table">
    <el-table
      :data="tableData"
      border
      stripe
      show-summary
      highlight-current-row
      :summary-method="getSum"
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
    >
      <el-table-column align="center" prop="collection_date" label="回款时间" min-width="13%" sortable> </el-table-column>
      <el-table-column align="center" prop="collection_amount" label="回款金额" min-width="12%"> </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" min-width="45%"> </el-table-column>
      <el-table-column align="center" prop="added" label="添加" min-width="15%"> </el-table-column>
      <el-table-column align="center" label="操作" min-width="15%">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)" v-if="userType.collection_edit">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="canDelete(scope.row)">删除</el-button>
          <el-button
            size="mini"
            :type="scope.row.is_caiwushenhe === true ? 'success' : 'warning'"
            @click="handleCaiwuhuikuan(scope.row)"
            v-if="userType.contract_financial_audit"
          >
            {{ scope.row.is_caiwushenhe === false ? '录入新的财务回款' : '财务回款已录入,可继续录入' }}</el-button
          >
          <!-- <el-button
            size="mini"
            :type="wuzidiaopei_btn_color(scope.row)"
            @click="handleWuzidiaopei(scope.row)"
            v-if="userType.wuzidiaopei_add"
            :disabled="wuzidiaopei_btn_disabled(scope.row)"
          >
            {{ wuzidiaopei_btn_text(scope.row) }}</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import utils from '../../assets/js/utils'

export default {
  data() {
    return {}
  },
  props: {
    tableData: Array,
    config: Object,
    userType: Object
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.userType.collection_del) {
          if (this.userType.id === 1) return true
          else {
            let added_person_time = utils.stringToDate(row.added.split('\n')[1])
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            // console.log('addedtime:', added_person_time)
            // console.log('now:', now)
            // console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          }
        } else return false
      }
    }
    // canFinancial_audit() {
    //   return function(row) {
    //     console.log(row)
    //   }
    // },
    //物资调配按钮显示文本
    // wuzidiaopei_btn_text() {
    //   return function(row) {
    //     if (row.is_caiwushenhe === false) {
    //       return '财务未审核'
    //     } else {
    //       if (row.is_diaopei === true) {
    //         return '已调配'
    //       } else {
    //         return '物资调配'
    //       }
    //     }
    //   }
    // },
    //物资调配按钮颜色
    // wuzidiaopei_btn_color() {
    //   return function(row) {
    //     if (row.is_caiwushenhe === false) {
    //       return 'danger'
    //     } else {
    //       if (row.is_diaopei === true) {
    //         return 'success'
    //       } else {
    //         return 'warning'
    //       }
    //     }
    //   }
    // },
    //物资调配按钮是否禁用
    // wuzidiaopei_btn_disabled() {
    //   return function(row) {
    //     if (row.is_caiwushenhe === false) {
    //       return true
    //     } else {
    //       if (row.is_diaopei === true) {
    //         return true
    //       } else {
    //         return false
    //       }
    //     }
    //   }
    // }
  },
  methods: {
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },
    //编辑
    handleEdit(row) {
      this.$emit('edit', row)
    },

    //删除
    handleDelete(row) {
      this.$emit('del', row)
    },
    handleCaiwuhuikuan(row) {
      this.$emit('addCaiwuhuikuan', row)
    },

    // handleWuzidiaopei(row) {
    //   this.$emit('wuziDiaopei', row)
    // },

    //设置特殊行（如已结单）的状态
    // tableRowClassName(row) {
    //   console.log('row......:', row)
    //   let rowIndex = row.rowIndex
    //   console.log('rowindex:', rowIndex)
    //   if (rowIndex === 0) {
    //     return 'warning-row'
    //   } else if (rowIndex === 1) {
    //     return 'success-row'
    //   }
    //   return ''
    // },
    //合计
    getSum(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        // column.property === 'bmoney'  bmoney 是你要总计table中的那一列的 prop值
        if (column.property === 'collection_amount') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          // 如果要格式化 或者处理数据  比方说加千位符,默认保留两位小数等等  直接处理sums[index]就可以
          sums[index] = sums[index].toFixed(2)
        } else {
          sums[index] = '--'
        }
      })
      return sums
    }
  }
}
</script>
